<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-12">
        <div class="card rounded-lg border border-dark">
          <h2 class="mx-auto mt-4">Organizing Institutions</h2>
          <hr />
          <div class="row mt-5 mx-5">
            <div class="col-md-6 mb-5">
              <div class="card" style="height: 300px">
                <div class="card-body h-100">
                  <div class="h-50 text-center">
                    <img
                      src="@/assets/ITU-logo-EN.png"
                      alt=""
                      style="height: 100%"
                      class="img-fluid"
                    />
                  </div>
                  <hr />
                  <div class="h-50 text-center">
                    <h5>Istanbul Technical University</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-5">
              <div class="card" style="height: 300px">
                <div class="card-body h-100">
                  <div class="h-50 text-center">
                    <img
                      src="@/assets/SAMSUN-logo-EN.png"
                      alt=""
                      style="height: 100%"
                      class="img-fluid"
                    />
                  </div>
                  <hr />
                  <div class="h-50 text-center">
                    <h5>Samsun University</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-5">
            <div class="col-md-6 mb-5">
              <div class="card" style="height: 300px">
                <div class="card-body h-100">
                  <div class="h-50 text-center">
                    <img
                      src="@/assets/MGM-logo.jpg"
                      alt=""
                      style="height: 100%"
                      class="img-fluid"
                    />
                  </div>
                  <hr />
                  <div class="h-50 text-center">
                    <h5>Turkish State Meteorological Service</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-5">
              <div class="card" style="height: 300px">
                <div class="card-body h-100">
                  <div class="h-50 text-center">
                    <img
                      src="@/assets/TMMOB-logo.jpg"
                      alt=""
                      style="height: 100%"
                      class="img-fluid"
                    />
                  </div>
                  <hr />
                  <div class="h-50 text-center">
                    <h5>TMMOB, Chamber of Meteorology Engineers</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
                    <div class="row mb-5 mx-5">
                        <div class="col-md-3"></div>
            <div class="col-md-6 mb-5">
              <div class="card" style="height: 300px">
                <div class="card-body h-100">
                  <div class="h-50 text-center">
                    <img
                      src="@/assets/meteouzal-logo-EN.jpg"
                      alt=""
                      style="height: 100%"
                      class="img-fluid"
                    />
                  </div>
                  <hr />
                  <div class="h-50 text-center">
                    <h5>ITU METEO IRS Team</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </main>
</template>
<script>
export default {
  name: "DuCorp",
  components: {},
};
</script>

<style></style>
